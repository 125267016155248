<script
    lang="ts"
    setup
>
    import type { RouteLocationNamedRaw } from 'vue-router'

    type Props = {
        to: RouteLocationNamedRaw
    }

    const props = defineProps<Props>()
</script>

<template>
    <div class="flex justify-center items-center mt-6">
        <AppHint
            v-if="$slots['hint-text']"
            key="hint"
        >
            <slot name="hint-text"/>
        </AppHint>

        <AppLink
            key="link"
            :to="props.to"
        >
            <slot name="link-text"/>
        </AppLink>
    </div>
</template>
